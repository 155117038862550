import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://eae4f50017da4a0e792841f7a2059e6c@o4506058062102528.ingest.sentry.io/4506058064265216',
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new Replay()]
});

export const handleError = handleErrorWithSentry();
